import $ from "jquery";

function mobileToggle() {
  const $body = $('body');
  const $shm = $('.site-header__menu');
  const mobileToggler = $('.site-header__mobile-hamburger');

  mobileToggler.on('click', function () {
    if ($(this).hasClass('site-header__mobile-hamburger--active') === true) {
      mobileToggler.removeClass('site-header__mobile-hamburger--active');
      $shm.removeClass('site-header__menu--active');
      $body.removeClass('fixed');
    } else {
      mobileToggler.addClass('site-header__mobile-hamburger--active');
      $shm.addClass('site-header__menu--active');
      $body.addClass('fixed');
    }
  });
}

function mobileChildMenus() {
  const menu = $('.site-header__menu--main-menu > ul, .site-header__menu--sub-menu > ul');
  const menuChildren = menu.children('li').find('ul').parent().addClass('has-children');

  if ($(window).width() <= 1024) {
    menu.find('li.has-children > a').click(function (e) {
      e.preventDefault();

      const nextSubMenu = $(this).next('ul');
      const thisLink = $(this).attr('href');
      const thisTitle = $(this).text();

      $(this).parent().toggleClass('active');

      if (nextSubMenu.children('.landing-link').length === 0) {
        nextSubMenu.prepend('<li class="landing-link"><a href="' + thisLink + '">' + thisTitle + ' Landing</a></li>');
      }
    });
  }
}

export function init() {
  mobileToggle();
  mobileChildMenus();
}

export default {
  init
}
