// Polyfill
import './base/_polyfill';
// external scripts
import $ from "jquery";
import debug from 'bows/dist/bows.min.js';

// example of ES2015 modules loading & tree shaking
import {
  emptyLinks
} from "./base/_links";

import size from "./base/_size";
import isTouch from "./base/_isTouch";
import fitvid from "./base/_fitvid";
import keyboardFocus from "./base/_keyboardFocus";
import smoothScroll from "./base/_smoothScroll";
import share from "./base/_share";
// import tracking from "./base/_tracking";

// Site
import general from "./_general";
import objectFitImages from 'object-fit-images';
import "focus-within-polyfill"
import picturefill from 'picturefill';
import mobileMenu from './base/_mobileMenu';
import exandingSearch from './base/_expandingSearch';
import sectionNav from './base/_sectionNav';
import loadMore from './base/_loadMore';
import fullScreenVideo from './base/_fullScreenVideo';

import map from './_map';
import youtube from './_youtube';

const $window = $(window);
const $document = $(document);
const log = debug("site");

class Site {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => this.ready());
    window.addEventListener('load', () => this.load());
  }

  ready() {
    $('html').removeClass('no-js').addClass('js');
    const log = debug("site:domReady");

    // tracking.init();

    isTouch.init();
    keyboardFocus.init();
    smoothScroll.init();
    share.init();
    size.init();
    picturefill();
    fitvid();
    objectFitImages();
    general.init();
    log('document.DOMContentLoaded');

    mobileMenu.init();
    exandingSearch.init();
    sectionNav.init();
    loadMore.init();
    this.fullScreenVideo();

    map.init();
    youtube.init();
  }

  load() {
    const log = debug("site:windowLoad");
    log("window.onload");


    if ($('figure img').length) {
      const figImg = $('figure img');
      const figImgWidth = figImg.width();

      figImg.next().css({
        'max-width': figImgWidth
      })
    }
  }

  fullScreenVideo() {
    Array.from(document.querySelectorAll('.hp-hero__modal-play')).forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault();
        new fullScreenVideo(node.href);
      });
    });

    Array.from(document.querySelectorAll('.video-module--play-button')).forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault();
        new fullScreenVideo(node.href);
      });
    });
  }

}

export default Site;
