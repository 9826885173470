// Useful library for working with maps. Currently not used.
//https://turfjs.org/

import $ from "jquery";
import debug from 'bows/dist/bows.min.js';

const $body = $('body');
const log = debug('map');
const isMapbox = document.querySelector('.mapbox');

const datasource = '/sites/default/files/map/combine.geojson';
// variable to store geojson
let geojson = '';

let tempGeoJson = [];

// variable for map name
let map = '';

// variable for popup
let pop = '';

// Holds visible features for fitering
let points = [];

// array to store years
let yearsFull = [];

// array to store organizations
let orgsFull = [];
let currentOrg = '';

// array to store focus
let focusFull = [];
let currentFocus = '';

// Listing column
let listingEl = '';

// Display wrapper
let mapWrap = '';

// Loading
let mapLoading = '';

// Select boxes
let orgSelect = '';
let focusSelect = '';
let startYearSelect = '';
let endYearSelect = '';
let areaSelect = '';
let mapControlSelects = '';

let orgSelect2 = ''
let focusSelect2 = '';
let startYearSelect2 = '';
let endYearSelect2 = '';
let areaSelect2 = '';
let currentEndYear = '';

let $mpcEndYear = $('#mpc-end-year');

let empty_text = $('.map__listing__empty').html();
let $mapListing = $('#map__listing');

let full_count = 0;
let yearAnimate = false;

let flickr_api = 'ec97e874b891dafc65e8765a1c495174';


function formatAward(cents) {
  return (Math.round(cents)).toLocaleString("en-US", {
    style: "currency",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: "USD"
  });
}

function formatCount(count) {
  return (Math.round(count)).toLocaleString("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
}

function allSelectsFilter(sYear, eYear, orgFilter, focusFilter) {
  let filtered = [];
  var features = map.queryRenderedFeatures({ layers: ['points'] });

  for (let i = 0; i < geojson.features.length; i++) {
    let props = geojson.features[i].properties;
    if (props) {
      if (props.y >= startYear && props.y <= endYear && props.f === focusFilter) {
        filtered.push(geojson.features[i]);

      }
    }
  }
  return filtered;
}

function focusSelectsFilter(startYear, endYear, focusFilter) {
  let filtered = [];
  var features = map.queryRenderedFeatures({ layers: ['points'] });
  // log(features);
  // log(geojson);

  for (let i = 0; i < geojson.features.length; i++) {
    let props = geojson.features[i].properties;
    if (props) {
      if (props.y >= startYear && props.y <= endYear && props.f === focusFilter) {
        filtered.push(geojson.features[i]);
      }
    }
  }
  return filtered;
}


function orgSelectsFilter(startYear, endYear, orgFilter) {
  let filtered = [];
  var features = map.queryRenderedFeatures({ layers: ['points'] });
  // log(features);
  // log(geojson);

  for (let i = 0; i < geojson.features.length; i++) {
    let props = geojson.features[i].properties;
    if (props) {
      if (props.y >= startYear && props.y <= endYear && props.o === orgFilter) {
        filtered.push(geojson.features[i]);

      }
    }
  }
  return filtered;
}

function yearsSelectsFilter(startYear, endYear) {
  let filtered = [];
  for (let i = 0; i < geojson.features.length; i++) {
    let props = geojson.features[i].properties;
    if (props) {
      if (props.y >= startYear && props.y <= endYear) {
        filtered.push(geojson.features[i]);
      }
    }
  }
  return filtered;
}

function selectChange() {
  mapLoading.classList.remove('map__loading--hide');

  const startYear = parseInt(startYearSelect.value);
  const endYear = parseInt(endYearSelect.value);
  const orgFilter = orgSelect.value;
  const focusFilter = focusSelect.value;

  let sYear = startYear;
  let eYear = endYear;
  currentOrg = orgFilter;
  currentFocus = focusFilter;

  if (startYear > endYear) {
    sYear = endYear;
    eYear = startYear;
  }

  setTimeout(() => {
    let filtered = []

    if (typeof sYear !== '' && typeof eYear !== '' && orgFilter !== '' && focusFilter !== '') {
      filtered = allSelectsFilter(sYear, eYear, orgFilter, focusFilter);
    }
    if (typeof sYear !== '' && typeof eYear !== '' && orgFilter !== '') {
      filtered = orgSelectsFilter(sYear, eYear, orgFilter);
    } else if (typeof sYear !== '' && typeof eYear !== '' && focusFilter !== '') {
      filtered = focusSelectsFilter(sYear, eYear, focusFilter)
    } else if (typeof sYear !== '' && typeof eYear !== '') {
      filtered = yearsSelectsFilter(sYear, eYear)
    }

    let newGeo = {
      'type': 'FeatureCollection',
      'features': filtered
    };

    map.getSource('geopoints').setData(newGeo);

    if (pop !== '') {
      pop.remove();
    }

    setTimeout(() => {
      mapLoading.classList.add('map__loading--hide');
      populateSidebar(filtered)
    }, 1000);
  }, 250);
}

function mapControls() {
  mapControlSelects.forEach(mcSelect => {
    mcSelect.addEventListener('change', (event) => {
      selectChange();
    });
  });

  $('#map__listing').on('click', '.project__toggle', function () {
    let $this = $(this);
    let $project = $this.closest('.project');
    let which = $this.attr('aria-controls');
    let isOpen = false;
    if (pop !== '') {
      pop.remove();
    }

    if ($this.attr('aria-expanded') === 'false') {
      $this.attr('aria-expanded', true);
    } else {
      $this.attr('aria-expanded', false);
    }
    $(`#${which}`).slideToggle();

    let lat = $project.data('lat');
    let lng = $project.data('lng');
    let flickr = $project.data('flickr');

    let photo_url = null;
    let photo_alt = '';
    if (flickr !== 'null') {
      let flickr_id = flickr;
      if (typeof flickr === 'string') {
        flickr = flickr.split(';');
        flickr_id = flickr[0];
      }

      $.getJSON(`https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=${flickr_api}&tags=nfwf:conprogramid=${flickr_id}&format=json&nojsoncallback=1`, function (json) {
        if (json.photos.total > 0) {
          let photo = json.photos.photo[0];
          photo_url = `https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_n.jpg`;
          photo_alt = photo.title;
        }

        if (isOpen === true && photo_alt !== 'Former NFWF Program') {
          $('.mapboxgl-popup-content').find('h2').before(`<div class="mapboxgl-image"><img src="${photo_url}" alt="${photo_alt}" /></div>`);
        }
      });
    }

    let coordinates = { lng: lng, lat: lat }
    let description = $project.find('.project__pop').html();

    if (lat && lng) {
      pop = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);

      isOpen = true;
    }
  });

  areaSelect2.on('change', function () {
    let $this = $(this);

    if ($this.val() !== ' ') {
      let $opt = $this.find('option:selected');
      let lat = $opt.data('lat');
      let long = $opt.data('long');
      let zoom = 6;
      if ($opt.data('zoom') !== undefined) {
        zoom = $opt.data('zoom');
      }

      map.flyTo({
        center: [
          long,
          lat,
        ],
        zoom: zoom,
        bearing: 0,
        speed: 2.5,
        curve: 1.5,
        easing: function (t) { return t; }
      });

      areaSelect2.val(' ');
    } else {
      return false;
    }
  });


  $('.map-controls__select').on('select2:select', function (e) {
    selectChange();
  });
}

function setOption(selectBox, data, text) {
  if (data !== 'null' && text !== 'null') {
    let option = document.createElement('option');
    option.setAttribute('value', data);
    option.text = text;
    selectBox.appendChild(option);
  }
}

function setSelectDisplay(selectBox, selectID) {
  if (selectID === 'mpc-end-year') {
    endYearSelect.selectedIndex = 0;
  } else if (selectID === 'mpc-start-year') {
    startYearSelect.selectedIndex = startYearSelect.options.length - 1;
  }
}

function setSelect(selectID, data, defaultValue) {
  const selectBox = document.getElementById(selectID);

  selectBox.innerHTML = '';
  if (defaultValue) {
    setOption(selectBox, '', defaultValue);
  }

  data.forEach(d => {
    if (typeof d === 'string' || typeof d === 'number') {
      setOption(selectBox, d, d);
    }
  });

  setSelectDisplay(selectBox, selectID);
}

function setData() {
  for (let i = 0; i < geojson.features.length; i++) {
    if (geojson.features[i]) {
      yearsFull.push(geojson.features[i].properties.y);
      orgsFull.push(geojson.features[i].properties.o);
      focusFull.push(geojson.features[i].properties.f);

      full_count++;
    }
  }

  setYears(yearsFull);
  setOrgs(orgsFull);
  setFocus(focusFull);

  $('.map-projects__count').text(formatCount(full_count));
}

function setYears(years) {
  let newYear = [];

  years = new Set(years);
  years.forEach(year => {
    if (year !== undefined) {
      newYear.push(parseInt(year));
    }
  });

  newYear = newYear.sort((a, b) => a - b);
  newYear = newYear.reverse();

  setSelect('mpc-start-year', newYear);
  setSelect('mpc-end-year', newYear);
}

function setOrgs(orgs) {
  orgs.sort();
  orgs = new Set(orgs);

  setSelect('mpc-organization', orgs, 'All Organizations');

  if (currentOrg !== '') {
    orgSelect2.val(currentOrg);
  }
  setTimeout(() => {
    let opts = orgSelect.options;
    for (let i = 0; i < opts.length; i++) {
      if (opts[i].value === currentOrg) {
        orgSelect.selectedIndex = i;
      }
    }
  }, 350);
}

function setFocus(focus) {
  focus.sort();
  focus = new Set(focus);

  setSelect('mpc-program', focus, 'All Programs');

  if (currentFocus !== '') {
    focusSelect2.val(currentFocus);
  }

  setTimeout(() => {
    let opts = orgSelect.options;
    for (let i = 0; i < opts.length; i++) {
      if (opts[i].value === currentFocus) {

        focusSelect.selectedIndex = i;
      }
    }
  }, 350);
}



function renderListings(features, updateFilters) {
  // Clear any existing listings

  listingEl.innerHTML = '';

  let focusArray = [];
  let organizationsAray = [];
  // log(features);
  if (features.length) {
    tempGeoJson = [];
    features = features.sort((a, b) => (a.properties.Title > b.properties.Title) ? 1 : -1);

    features.forEach(function (feature) {
      let prop = feature.properties;
      let geo = feature.geometry;
      let focusText = '';
      let orgText = '';
      let descriptionText = '';

      tempGeoJson.push(prop);

      if (prop.s === undefined || prop.s === 'null') {
        if (prop.t === undefined || prop.t === 'null') {
          return false;
        } else {
          prop.s = prop.t;
        }
      }

      if (prop.f) {
        focusArray.push(prop.f);
        focusText = `<p class="project__program"><strong>Conservation Program:</strong><br>${prop.f}</p>`;
      }

      if (prop.o) {
        organizationsAray.push(prop.o);
        orgText = `<p class="project__program"><strong>Organization:</strong><br>${prop.o}</p>`;
      }

      if (prop.d) {
        descriptionText = `<p>${prop.d}</p>`;
      }

      let description = '';
      if (prop.s !== 'null' && prop.d !== 'null') {
        description = `
        <time class="project__year">${prop.y}</time>
        <h2 class="project__title">
          <button type="button" id="project-button-${ prop.id}" class="project__toggle" aria-controls="project-description-${prop.id}" aria-expanded="false">${prop.s}</button>
        </h2>
        <div class="project__description" id="project-description-${ prop.id}" aria-labelledby="project-button-${prop.id}">
          ${focusText}
          ${orgText}
          ${descriptionText}
          <p class="project__amount">Award Amount: <span class="project__amount__number">${formatAward(prop.a)}</span></p>
         </div>

         <div class="project__pop">
          <h2>${prop.s}</h2>
           ${descriptionText}
        </div>
			`;
      }

      var item = document.createElement('div');
      item.classList.add('project');
      item.setAttribute('data-id', prop.id);
      item.setAttribute('data-lat', geo.coordinates[1]);
      item.setAttribute('data-lng', geo.coordinates[0]);
      item.setAttribute('data-flickr', prop.g);
      item.innerHTML = description;

      listingEl.appendChild(item);
    });


    if (updateFilters !== false) {
      setFocus(focusArray);
      setOrgs(organizationsAray);
    }
  } else {
    var empty = document.createElement('div');
    empty.classList.add('map__listing__empty');
    empty.innerHTML = empty_text;

    listingEl.appendChild(empty);
  }

}

function getUniqueFeatures(array, comparatorProperty) {
  var existingFeatureKeys = {};
  // Because features come from tiled vector data, feature geometries may be split
  // or duplicated across tile boundaries and, as a result, features may appear
  // multiple times in query results.
  var uniqueFeatures = array.filter(function (el) {
    if (existingFeatureKeys[el.properties[comparatorProperty]]) {
      return false;
    } else {
      existingFeatureKeys[el.properties[comparatorProperty]] = true;
      return true;
    }
  });

  return uniqueFeatures;
}

function populateSidebar() {
  let zoomLevel = map.getZoom();

  mapWrap.classList.add('map__wrap--active');
  var features = map.queryRenderedFeatures({ layers: ['points'] });

  if (features) {
    var uniqueFeatures = getUniqueFeatures(features, "id");
    // Populate features for the listing overlay.
    renderListings(uniqueFeatures);

    // Store the current features in points array for later uses
    points = features;
  }

}


function slideToSidebar(projectId) {
  let projectAccordion = $(`[data-id="${projectId}"]`).eq(0);
  if (projectAccordion.length) {

    let button = projectAccordion.find(`#project-button-${projectId}`);
    if (button.attr('aria-expanded') === 'false') {
      button.trigger('click');
    }
    $mapListing.scrollTop(0);
    let topDistance = projectAccordion.position().top;

    $mapListing.animate({ scrollTop: topDistance }, 350);

  }
}


$('input[name="yr"]').on('change', function () {
  let yrType = $(this).val();

  if (yrType === 'year_range') {
    $('.map-range-hide--hiden').removeClass('map-range-hide--hiden');
    $mpcEndYear.val(currentEndYear).trigger('change.select2');
    selectChange();

  } else if (yrType == 'single_year') {
    currentEndYear = endYearSelect.value;
    let startYear = startYearSelect.value;

    $mpcEndYear.val(startYear).trigger('change.select2');
    selectChange();
    $('.map-range-hide').addClass('map-range-hide--hiden');
  }
});
//Animate Years Functionality
$('button[data-js="animateYears"]').on('click', function () {
  if (yearAnimate === false) {
    yearAnimate = true;
    const startYear = parseInt(startYearSelect.value);
    const endYear = parseInt(endYearSelect.value);
    const orgFilter = orgSelect.value;
    const focusFilter = focusSelect.value;


    let filtered = [];
    let sYear = startYear;
    let eYear = endYear;

    if (pop !== '') {
      pop.remove();
    }

    if (startYear > endYear) {
      sYear = endYear;
      eYear = startYear;
    }

    let diff = eYear - sYear;

    if (diff > 1) {
      for (let i = 0; i < diff; i++) {
        let timer = i * 1000;
        setTimeout(() => {
          if (yearAnimate === true) {
            animateYears(sYear, sYear + i);
          }
        }, timer);
      }
    }

    $('.map-animate__svg__play').hide();
    $('.map-animate__svg__pause').show();
  } else {
    yearAnimate = false
    $('.map-animate__svg__play').show();
    $('.map-animate__svg__pause').hide();
  }

});

function animateYears(sYear, eYear) {
  if (yearAnimate === true) {

    let filtered = yearsSelectsFilter(sYear, eYear)

    let newGeo = {
      'type': 'FeatureCollection',
      'features': filtered
    };


    map.getSource('geopoints').setData(newGeo);
    $mpcEndYear.val(eYear).trigger('change.select2');

  }
}

// Set up the map and controls
function initMap() {
  mapboxgl.accessToken = 'pk.eyJ1IjoibmZ3ZiIsImEiOiJjanhuZXVhNDQwZHloM2lsMzJseWlndW5lIn0.p_Zb0O49DVmCdIKFiSB6dA';
  map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/nfwf/ck40h9qtv1b4i1cttsqcxufj7',
    center: [-103.59179687498357, 40.66995747013945],
    zoom: 3,
    maxZoom: 10,
    minZoom: 2
  });

  // disable map rotation using right click + drag
  map.dragRotate.disable();

  // disable map rotation using touch rotation gesture
  map.touchZoomRotate.disableRotation();

  map.addControl(new mapboxgl.NavigationControl({
    showCompass: false,
    visualizePitch: false
  }));


  map.on('load', function () {
    // console.log(typeof geojson);
    // console.log(geojson);
    map.addSource('geopoints', {
      type: 'geojson',
      data: geojson,
      cluster: true,
      clusterMaxZoom: 5, // Max zoom to cluster points on
      clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
    });

    // #f50709
    map.addLayer({
      id: 'points',
      type: 'circle',
      source: 'geopoints',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#2C84AB',
        'circle-radius': 4,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#FFFFFF'
      }
    });

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'geopoints',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#2C84AB',
          250, '#2C84AB',

        ],
        'circle-stroke-color': '#ffffff',
        'circle-stroke-width': 2,
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'geopoints',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 16,
      },
      paint: {
        'text-color': '#ffffff'
      }
    });

    // inspect a cluster on click
    map.on('click', 'clusters', function (e) {
      var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      var clusterId = features[0].properties.cluster_id;
      map.getSource('geopoints').getClusterExpansionZoom(clusterId, function (err, zoom) {
        if (err)
          return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom + 1
        });
      });

      populateSidebar();
    });

    map.on('click', 'points', function (e) {

      // console.log(e.features[0].properties.EZG_ID);
      var coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let description = '';

      if (e.features[0].properties.s !== 'null' && e.features[0].properties.d !== 'null') {
        description = `
          <h2>${e.features[0].properties.s}</h2>
          <p>${e.features[0].properties.d}</p>
        `;
      } else if (e.features[0].properties.s !== 'null') {
        description = `<h2>${e.features[0].properties.s}</h2>`;
      } else if (e.features[0].properties.d !== 'null') {
        description = `<p>${e.features[0].properties.d}</p> `;
      }


      if (e.features[0].properties.s !== '' && e.features[0].properties.s !== undefined) {
        pop = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map);
      }

      slideToSidebar(e.features[0].properties.id);
    });

    map.on('mouseenter', 'points', function () {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'points', function () {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'clusters', function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', function () {
      map.getCanvas().style.cursor = '';
    });

    map.on('zoomstart', 'points', function () {
      $('.mapboxgl-popup').addClass('mapboxgl-popup--hide');
    });

    map.on('zoomend', 'points', function () {
      $('.mapboxgl-popup').removeClass('mapboxgl-popup--hide');
    });

    map.on('moveend', function () {
      setTimeout(() => {
        populateSidebar();
      }, 500);
    });

    mapLoading.classList.add('map__loading--hide');
  });
  // renderListings([]);
}

export function init() {
  log($body.width());
  if (isMapbox !== null && $body.width() >= 1024) {
    // Listing column
    listingEl = document.getElementById('map__listing');

    // Display wrapper
    mapWrap = document.getElementById('map__wrap');

    // Loading
    mapLoading = document.getElementById('map__loading');

    // Select boxes
    orgSelect = document.getElementById('mpc-organization');
    focusSelect = document.getElementById('mpc-program');
    startYearSelect = document.getElementById('mpc-start-year');
    endYearSelect = document.getElementById('mpc-end-year');
    areaSelect = document.getElementById('mpc-area-select');
    mapControlSelects = document.querySelectorAll('.map-controls__select');

    orgSelect2 = $('#mpc-organization').select2();
    focusSelect2 = $('#mpc-program').select2();
    startYearSelect2 = $('#mpc-start-year').select2();
    endYearSelect2 = $mpcEndYear.select2();
    areaSelect2 = $('#mpc-area-select').select2();


    fetch(datasource)
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        geojson = myJson;
        initMap();
        setData();
      });

    mapControls();
  } else {
    $('#mapbox-container').hide();
  }
}

export default {
  init
};
