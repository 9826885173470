import $ from "jquery";
import debug from 'bows/dist/bows.min.js';
import Glide from '@glidejs/glide';

const log = debug("general");
const body = $('body');
const $window = $(window);

const $speciesDrawerBtn = $('.species-drawer__click-container');
const $tabbedContent = $('.tabbed-content');
const $hpPartnershipsRow = $('.hp-partnerships__row');
const $accordion = document.getElementsByClassName('accordion');
const $heroImageContainer = $('.hero__image-container');

let videoTextAdded = false;

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getVideoText(time) {
  for (var i = 0; i < heroText.length; i++) {
    if (time >= heroText[i].start) {
      $('.hp-hero__text').text(heroText[i].text);
    }
  }
}

function speciesDrawer() {
  // Cached some variables
  const $speciesDrawerRow = $('.species-drawer__row');
  $speciesDrawerBtn.click(function () {
    let $this = $(this);
    let $parent = $this.parent();
    var content = $parent.find('.species-drawer__content'),
      contentHeight = content.outerHeight();


    if ($parent.hasClass('active')) {
      $parent.removeClass('active');
      $parent.animate({
        'margin-bottom': 0
      });
      content.removeClass('content-active').fadeOut('fast');
    } else {
      $speciesDrawerRow.find('.species-drawer__columns.active').removeClass('active').animate({ 'margin-bottom': 0 });
      $speciesDrawerRow.find('.species-drawer__content.content-active').removeClass('content-active').fadeOut('fast');

      $parent.addClass('active');
      $parent.animate({
        'margin-bottom': contentHeight
      });
      content.addClass('content-active').fadeIn('slow');
    }
  });
}

function doTabs() {
  $tabbedContent.each(function () {
    let $tabs = $(this).find('[data-tab]');
    let $tc = $(this);

    $tabs.eq(0).addClass('current');
    $tabs.click(function () {
      let $this = $(this);
      let tab_id = $this.attr('data-tab');

      $tc.find('.current').removeClass('current');

      $this.addClass('current');
      $(`#${tab_id}`).addClass('current');

      if (history.pushState) {
        var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?activeTab=' + tab_id;

        window.history.pushState({ path: newurl }, '', newurl);
      }
    });
  });

}

function heroVideoText() {
  let heroVideo = document.getElementById('background-video');
  let $hpHeroText = $('.hp-hero__text');

  if ($window.scrollTop() > 0) {
    $hpPartnershipsRow.animate({
      'top': '-100px'
    });
    $hpHeroText.css('opacity', 0);
  }

  $window.scroll(function () {
    if ($window.scrollTop() > 0 && $window.scrollTop() < 50) {
      $hpPartnershipsRow.animate({
        'top': '-100px'
      }, 250);
      $hpHeroText.css('opacity', 0);

    } else if ($window.scrollTop() < 50) {
      $hpPartnershipsRow.animate({
        'top': '0px'
      }, 250);

      $hpHeroText.css('opacity', 1);
    }
  });


  setInterval(function () {
    getVideoText(heroVideo.currentTime);
  }, 100);
}

function accordion() {
  var i;

  for (i = 0; i < $accordion.length; i++) {
    $accordion[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
}

function heroImageAction() {
  const $heroContentContainer = $('.hero__content-container');
  const $heroImage = $heroImageContainer.find('img');
  $(window).scroll(function (e) {
    let scrolled = $(window).scrollTop();
    let scaled = 1 + (scrolled * 0.0015);

    $heroImage.css('transform', `translate(-50%, 0) scale(${scaled})`);
    // $heroImageContainer.css('opacity', 1 - (scrolled * .00125));
    $heroContentContainer.css('top', (scrolled * -0.005) + 'rem');
    $heroContentContainer.css('opacity', 1 - (scrolled * .00025));
  });
}

export function init() {

  if ($tabbedContent.length) {
    doTabs();
  }

  if ($speciesDrawerBtn.length) {
    speciesDrawer();
  }


  if ($hpPartnershipsRow.length) {
    heroVideoText();
  }

  if ($accordion.length) {
    accordion();
  }

  if ($('.hp-press .glide').length) {
    const glide = new Glide('.glide');

    glide.mount();
  }

  if ($heroImageContainer.length) {
    heroImageAction();
  }
}

export default {
  init
};
