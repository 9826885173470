import $ from "jquery";

function initLoadMore() {
	const loadMore = $('[data-load-more]');

	if ( loadMore.length ) {
		if ( loadMore.length > 0 ) {
			loadMore.each(function(){
				loadMoreController( $(this) );
			});
		} else {
			loadMoreController( loadMore );
		}
	}
}

function loadMoreController( parent ) {
	const loadMoreChildren = parent.find('[data-load-container]').children();
	
	if ( loadMoreChildren.length > 3 ) {
		hideElements( loadMoreChildren );

		const loadMoreButton = parent.append('<button class="load-more__button">Load More</button>');

		loadMoreButton.on('click', '.load-more__button', function() {
			if ( $(this).hasClass('load-more__button--loaded') ) {
				$(this).removeClass('load-more__button--loaded').text('Load More');
				fadeOutElements( loadMoreChildren );
			} else {
				$(this).addClass('load-more__button--loaded').text('Show Less');
				loadMoreChildren.parent().find('.load-more--hide').removeClass('load-more--hide').fadeIn();
			}
		});
	}
}

function hideElements( elem ) {
	elem.each(function(index){
		if ( index > 2 ) {
			$(this).addClass('load-more--hide').hide();
		}
	});
}

function fadeOutElements( elem ) {
	elem.each(function(index){
		if ( index > 2 ) {
			$(this).addClass('load-more--hide').fadeOut();
		}
	});
}

export function init() {
	initLoadMore();
}

export default {
	init
};