import $ from "jquery";

function expandingSearch() {
  const searchToggle = $('.search-toggle');
  const searchField = $('.site-header__search-container');
  const searchClose = $('.site-header__search-close');

  searchToggle.click(function () {
    if (!searchField.hasClass('active')) {
      searchField.addClass('active');
      searchField.find('input[type="text"], input[type="search"]').focus();
    }
  });

  searchClose.click(function () {
    if (searchField.hasClass('active')) {
      searchField.removeClass('active');
    }
  });
};

export function init() {
  expandingSearch();
}

export default {
  init
};
