export default class fullScreenVideo {
	constructor( src ) {
		this.src     = src;
		this.sandbox = document.createElement('div');
		this.video   = document.createElement('video');
		this.source  = document.createElement('source');

		this.video.setAttribute('playsinline', '');
		this.video.setAttribute('webkit-playsinline', '');
		this.video.width = '100%';
		this.video.height = '100%';

		this.source.src = this.src;

		if ( this.isYouTube() ) {
			this.source.type = 'video/youtube';
			this.sizeYouTube();
		} else if ( this.isMp4() ) {
			this.source.type = `video/mp4`;
		} else {
			return;
		}

		this.video.appendChild(this.source);
		this.sandbox.appendChild(this.video);
		document.body.appendChild(this.sandbox);

		this.player = new MediaElementPlayer( this.video, {
			success: ( media, video, player ) => {
				player.enterFullScreen();

				media.addEventListener( 'ended', () => {
					player.exitFullScreen();
				} );

				player.container.addEventListener('exitedfullscreen', () => {
					this.destroy();
				} );
			},
			error: () => {
				this.destroy();
			}
		});

		this.player.getSrc();
	}

	destroy() {
		if ( this.sandbox.parentNode ) {
			this.sandbox.parentNode.removeChild(this.sandbox);
		}

		if ( this.style && this.style.parentNode ) {
			this.style.parentNode.removeChild(this.style);
		}
	}

	isYouTube() {
		return this.src.match( /^https?:\/\/(?:www.)?(youtube)\.com/ );
	}

	isMp4() {
		return this.src.match( /\.mp4$/ );
	}

	isSupported() {
		return ( this.isMp4() || this.isYouTube() ) ? true : false;
	}

	sizeYouTube() {
		let id = 'fsv-yt-css';
		this.style = document.getElementById(id);

		if ( ! this.style ) {
			this.style = document.createElement('style');
			this.style.id = id;

			let css = `mediaelementwrapper iframe { height: 100%; width: 100%; }`;

			if ( this.style.styleSheet ) {
				this.style.styleSheet.cssText = css;
			} else {
				this.style.appendChild(document.createTextNode(css));
			}

			document.head.appendChild(this.style);
		}
	}
}