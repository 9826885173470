import $ from "jquery";
import debug from 'bows/dist/bows.min.js';
import fitvid from './base/_fitvid';
import isTouch from './base/_isTouch';

const log = debug("youtube");
const $body = $('body');
const body = document.querySelector("body");

let $modal = '';
let hero_video = '';

window.wdg_youtube = {
  videoArray: [],
  videoCount: 0,
  videos: '',

};

function esc() {
  document.addEventListener("keydown", function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key == "Escape" || evt.key == "Esc";
    } else {
      isEscape = evt.keyCode == 27;
    }

    if (isEscape && body.classList.contains("youtube-playing")) {
      closeModal();
    }
  });
}

function closeModal(autoPlay) {
  $modal.fadeOut();
  $body.removeClass('youtube-playing');

  if (autoPlay !== false) {
    wdg_youtube_pauseVideos();
  }

  $('.return_focus').focus().removeClass('return_focus');
}

function openModal($this, autoPlay) {
  $modal = $('#' + $this.data('youtubemodal'));
  const YT = $modal.find('.video-player').attr('id').split('player');
  const which = YT[1];

  $modal.fadeIn().focus();
  $body.addClass('youtube-playing');

  if (autoPlay !== false) {
    wdg_youtube_pauseVideos();
    wdg_youtube.videoArray[which].setVolume(20);
    wdg_youtube.videoArray[which].playVideo();
  }


}

function addYouTubeAPI() {
  var tag = document.createElement('script'),
    firstScriptTag = document.getElementsByTagName('script')[0];

  tag.src = "https://www.youtube.com/iframe_api";
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  log('addYouTubeAPI');
}

function onPlayerStateChange(event) {
  // Get Video Title
  log(event.data);
  if (event.data === -1) {
    //unstarted
  } else if (event.data === 0) {
    //ended
    //modal.closeModal();
  } else if (event.data === 2) {
    //paused
  } else if (event.data === 3) {
    //buffering
  } else if (event.data === 5) {
    //video cued
  }
}

function onPlayerReady() {
  $('[data-youtubemodal').on('click', function (e) {
    // if (e.type === 'touchstart') {

    //   //openModal($(this), false);
    //   openModal($(this), true);
    // } else if (e.type === 'click') {
    //   openModal($(this), true);
    // }
    $(this).addClass('return_focus');
    openModal($(this), true);
  });

}

window.wdg_youtube_pauseVideos = function () {
  let YTW = $('.video-player');
  YTW.each(function () {

    let YT = $(this).attr('id').split('player'),
      whichP = YT[1];

    wdg_youtube.videoArray[whichP].pauseVideo();
  });

}

window.onYouTubeIframeAPIReady = function () {
  log('youtube ready');
  wdg_youtube.videos.each(function () {
    var video = $(this),
      vidPlay = video.data('yt'),
      vidID;


    let splitWatch = vidPlay.split('v=');
    let splitSlash = vidPlay.split('/');
    if (splitWatch.length === 2) {
      let splitWatchParam = splitWatch[1].split('&');
      vidID = splitWatchParam[0];
    } else if (splitSlash.length > 1) {
      let splitSlashParam = splitSlash.pop().split('?');
      vidID = splitSlashParam[0];

    } else {
      vidID = vidPlay;
    }

    var playerName = 'player' + wdg_youtube.videoCount;
    video.attr('data-YTA', playerName);
    video.append('<div id="' + playerName + '" class="video-player"/>');

    let player_video = new YT.Player(playerName, {
      height: '529',
      width: '940',
      videoId: vidID,
      playerVars: {
        rel: 0,
        loop: 1,
        html5: 1
      },
      events: {
        'onStateChange': onPlayerStateChange,
        'onReady': onPlayerReady
      }
    });
    wdg_youtube.videoArray.push(player_video);
    wdg_youtube.videoCount++;
  });
  $('body').addClass('yt-ready');
  fitvid();
}

export function init() {
  wdg_youtube.videos = $('[data-yt]');
  if (wdg_youtube.videos.length > 0) {
    addYouTubeAPI();
    esc();
    $('.youtube-modal__close').on('click', function (e) {
      // if (e.type === 'touchstart') {
      //   closeModal(false);
      // } else if (e.type === 'click') {
      //   closeModal(true);
      // }

      closeModal(true);
    });
  }


}

export default {
  init
};
