import $ from "jquery";
import Glide from '@glidejs/glide';
//import simplebar from 'simplebar'
import slick from 'slick-carousel';
import debug from 'bows/dist/bows.min.js';

const log = debug("sectionnav");

let $sn = $('.sectionnav__ul');


function checkNextArrow() {

  let afterWidth = 0;
  let $current = $sn.find('.slick-current');
  let $after = $current.nextAll();
  let $afterBtn = $sn.closest('.sectionnav').find('.slick-arrow--next');


  afterWidth += $current.width();

  $after.each(function () {
    afterWidth += $(this).width();
  });

  if (afterWidth > $sn.width()) {

    $afterBtn.attr({ 'aria-disabled': false, }).removeAttr('disabled').removeClass('slick-disabled');
  } else {

    $afterBtn.attr({ 'aria-disabled': true, 'disabled': 'disabled' }).addClass('slick-disabled');
  }
}

export function init() {

  let $sectionnav = $sn.slick({
    dots: false,
    prevArrow: '.slick-arrow--prev',
    nextArrow: '.slick-arrow--next',
    draggable: false,
    infinite: false,
    speed: 300,
    centerMode: false,
    variableWidth: true,
    outerEdgeLimit: true,
  });
  setTimeout(() => {
    checkNextArrow();
  }, 100);

  $sectionnav.on('afterChange', function () {
    log('After Change');
    checkNextArrow();
  });



  var endResizeEvent;
  $(window).on('resize', function () {
    clearTimeout(endResizeEvent);
    endResizeEvent = setTimeout(function () {
      // Run code here, resizing has "stopped"
      checkNextArrow();
    }, 100);
  });

  if ($('.section-nav .glide').length) {
    const glide = new Glide('.glide', {
      type: 'slider',
      bound: true,
      rewind: false,
      peak: 100,
      breakpoints: {
        1070: {
          perView: 5
        },
        768: {
          perView: 3
        },
        416: {
          perView: 2
        }
      }
    });

    glide.update({
      perView: $(glide.selector).attr('data-per-view'),
      gap: $(glide.selector).attr('data-gap'),
    });

    glide.on('build.after', function () {
      if (glide.settings.perView > 5 && window.innerWidth >= 1071) {
        $('.glide__arrows').hide();
      }
      if (window.innerWidth <= 1070) {
        if (!$('.glide__arrows').is(':visible')) {
          $('.glide__arrows').show();
        }
      }
    });

    glide.on('run', function () {
      if (glide.index === 0) {
        $('.glide__arrow--left').fadeOut();
      } else if (glide.index === 1) {
        $('.glide__arrow--left').fadeIn();
      }
    });

    glide.mount();
  }
}

export default {
  init
};
